import logo from './assets/logo-tu-las--black.svg';

import OrderProvider, { useOrderContext } from './context/OrderContext';

import Summary from './components/Summary';
import ProductGroups from './components/ProductGroups';

import './App.scss';
import 'bootstrap/dist/css/bootstrap.css';


function App() {

  const { isLocked } = useOrderContext();

  return (
    <div className="app">
      <div className="row">
        <div className="col-7 col-xl-8">
          <header className="app-header">
            <img src={'/img/logo/' + window.APP_SETTINGS?.clientSettings?.LOGO} className="app-logo" alt="logo" />
          </header>
          <section className={`app-content ${isLocked ? 'app-content--lock' : ''}`}>
            <ProductGroups groups={window.APP_SETTINGS?.productGroups} />
          </section>
        </div>
        <div className="col-5 col-xl-4 app-sidebar">
          <Summary name="test" />
        </div>
      </div>
    </div>
  );
}

export default () => (
  <OrderProvider>
    <App />
  </OrderProvider>
);
