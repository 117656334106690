import React from 'react';
import ProductVariant from './ProductVariant';

const ProductWithVariants = ({ product = {}, variants = [] }) => {
    return (
        <div className="product-group__variant">
            <div className="product-group__products">
                <div className="product-variant__wrapper" dangerouslySetInnerHTML={{ __html: product.name.replace(',', '<br />') }} />
                {variants.map((v, idx) => {
                    return product.variants[v] ? <ProductVariant key={idx} variant={product.variants[v]} /> : null
                })}
            </div>
        </div>
    );
}


export default ProductWithVariants