import React, { useState } from 'react';
import axios from 'axios';

import { useOrderContext } from '../context/OrderContext';
import SuccessModal from './SuccessModal';

const OrderForm = ({ cart }) => {
    const { quantities, data, setField, clearAll, isLocked, setLock } = useOrderContext()

    const [resErrors, setResErrors] = useState({});
    const [modalData, setOpenModal] = useState({});

    const handleClearData = (e) => {
        e.preventDefault();
        clearAll();
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (isLocked)
            return;

        setResErrors({})

        const formData = {
            data: { ...data },
            quantities: { ...quantities },
            cart: { ...cart }
        };
        const customConfig = {
            headers: {
                'Accept': 'application/json'
            }
        };

        setLock(true);
        axios.post('/request/add', formData, customConfig)
            .then(({ data }) => {
                if (data.errors) {
                    setResErrors(data.errors);
                    return;
                } else {
                    setOpenModal({ "message": "Thank you for your request. <br />Your request number is: " + data?.model?.id + "<br />Confirmation has been sent on your e-mail" });
                }
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(() => {
                setLock(false);
            });
    }

    const handleChange = (event) => {
        setField(event.target.name, event.target.value);
    }
    const handleChangeCheckbox = (event) => {
        setField(event.target.name, !!event.currentTarget.checked);
    };

    const mailError = resErrors.hasOwnProperty('email');
    const termError = resErrors.hasOwnProperty('terms');

    return <>
        <h2>Send your request</h2>

        <form onSubmit={handleSubmit}>

            <div className="mb-3">
                <div className="form-floating">
                    <input value={data.email} onChange={handleChange} required type="email" name="email" className={`form-control ${mailError && 'border-danger'}`} id="OrderFormEmail" placeholder="user@gmail.com"
                        disabled={isLocked}
                    />
                    <label htmlFor="OrderFormEmail">Email address</label>
                </div>
                {mailError && (
                    <p className="text-danger">{resErrors.email[0]}</p>
                )}
            </div>

            <div className="form-floating mb-3">
                <input value={data.phone} onChange={handleChange} required type="text" name="phone" className="form-control" id="OrderFormPhone" placeholder="+48 123 456 789"
                    disabled={isLocked}
                />
                <label htmlFor="OrderFormPhone">Phone</label>
            </div>

            <div className="form-floating mb-3">
                <textarea value={data.billingAddress} onChange={handleChange} name="billingAddress" className="form-control" id="OrderFormBillingAddress" placeholder="Your address" style={{ height: 100 }}
                    disabled={isLocked}
                ></textarea>
                <label htmlFor="OrderFormBillingAddress">Billing address (fill to get a proforma)</label>
            </div>

            <div className="form-floating mb-3">
                <textarea value={data.deliveryAddress} onChange={handleChange} name="deliveryAddress" className="form-control" id="OrderFormDeliveryAddress" placeholder="Your address" style={{ height: 100 }}
                    disabled={isLocked}
                ></textarea>
                <label htmlFor="OrderFormDeliveryAddress">Delivery address (fill to get shipping est.)</label>
            </div>

            <div className="form-floating mb-3">
                <textarea value={data.message} onChange={handleChange} name="message" className="form-control" id="OrderFormMessage" placeholder="Message" style={{ height: 100 }}
                    disabled={isLocked}
                ></textarea>
                <label htmlFor="OrderFormDeliveryAddress">Any other requests?</label>
            </div>

            <div className="mb-3">
                <div className={`form-check  ${termError && 'form-check--error'}`}>
                    <input value={data.terms} onChange={handleChangeCheckbox} name="terms" type="checkbox" className="form-check-input" id="OrderFormTerms"
                        disabled={isLocked}
                    />
                    <label className="form-check-label" htmlFor="OrderFormTerms">I accept the terms and condition of this service</label>
                </div>
                {termError && (
                    <p className="text-danger">{resErrors.terms[0]}</p>
                )}
            </div>

            <div className="mb-3 form-check">
                <input value={data.newsletter} onChange={handleChangeCheckbox} name="newsletter" type="checkbox" className="form-check-input" id="OrderFormNewsletter"
                    disabled={isLocked}
                />
                <label className="form-check-label" htmlFor="OrderFormNewsletter">Sign me in to the B2B newsletter</label>
            </div>

            <div className="d-grid gap-2">
                <button type="submit" className="btn btn-primary btn" disabled={isLocked}>
                    Send request {isLocked && (
                        <div class="spinner-border spinner-border-sm" role="status" />
                    )}
                </button>
                <br /><br /><br /><br />
                <button onClick={handleClearData} className="btn btn-link btn-sm" disabled={isLocked}>
                    Clear form
                </button>
            </div>
        </form>
        <SuccessModal modalData={modalData} />
    </>;
}


export default OrderForm