import React from 'react';
import { useOrderContext } from '../context/OrderContext';
import OrderForm from './OrderForm';

import './Summary.scss';

const Summary = (props) => {
    const { quantities, getCart } = useOrderContext()
    const cart = getCart();

    return <div className="summary--wrapper">
        <div className="summary">
            <h2 className="mb-3">ORDER SUMMARY</h2>
            <table className="table">
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        {/* <th colSpan="2">RRP</th> */}
                        <th colSpan="2">WHOLESALE</th>
                    </tr>
                </thead>
                <tbody>
                    {cart.items.map(item => {
                        return <tr key={item.lp}>
                            <td>{item.lp}.</td>
                            <td>
                                {item.name} / {item.variant}
                                <br />
                                <small>{item.SKU}</small>
                            </td>
                            {/*
                            <td>
                                {item.count} x {item.price}€
                            </td>
                            <td>{item.value}€</td>
                            */}
                            <td>
                                {item.count} x {item.wholesalePrice}€
                            </td>
                            <td>{item.wholesaleValue}€</td>
                        </tr>
                    })}
                </tbody>
                {cart.sum > 0 && <tfoot>
                    <tr>
                        <th colSpan="2" className="text-end">TOTAL WHOLESALE</th>
                        <th>{cart.pcs}pcs.</th>
                        <th>{cart.sumWholesale}€</th>
                    </tr>
                    <tr>
                        <th colSpan="2" className="text-end">DISCOUNT:</th>
                        <th></th>
                        <th>{cart.discount}%</th>
                    </tr>
                    <tr>
                        <th colSpan="2" className="text-end">RRP</th>
                        <th>{cart.pcs}pcs.</th>
                        <th>{cart.sum}€</th>
                    </tr>
                </tfoot>}
            </table>

            {cart.moq &&
                <div className="alert alert-warning text-center" role="alert">
                    MOQ: <strong>{cart.moq.value}€</strong>
                    <br />
                    You need to order for <strong>{cart.moq.missing}€</strong> more to achieve MOQ
                </div>}

            {!cart.moq && <div className="alert alert-info text-center" role="alert">
                Wholesale discount level for your order: <strong>{cart.discount}%</strong>.
                <br />
                {cart.nextDiscount ? <>
                    To get <strong>{cart.nextDiscount.discount}%</strong> wholesale discount you need
                    <br />
                    to order for <strong>{cart.nextDiscount.missing}€</strong> more
                </> : <>This is the maximum discount</>}
            </div>}

            {cart.sum > 0 && <OrderForm cart={cart} />}
        </div>
    </div>;
}


export default Summary