import React, { useEffect, useState } from "react"

const OrderContext = React.createContext({ quantities: {}, data: {} });

const getInitialState = (name, defalutValue = {}) => {
    const value = localStorage?.getItem(name)
    return value ? JSON.parse(value) : defalutValue
}

const OrderProvider = ({ children, initialCount = 0 }) => {
    const [quantities, setQuantities] = React.useState(getInitialState('quantities'))
    const [data, setData] = React.useState(getInitialState('data'))
    let variants = {};

    const [isLocked, setLock] = useState(false);

    useEffect(() => {
        localStorage?.setItem('quantities', JSON.stringify(quantities))
    }, [quantities])

    useEffect(() => {
        localStorage?.setItem('data', JSON.stringify(data))
    }, [data])

    const clearAll = () => {
        setQuantities({});
        setData({});
    }

    const setQuantity = (sku, count) => {
        const newQuantities = { ...quantities };

        if (count < 0) {
            // ignoruj ujemne
        } else if (count == 0) {
            delete newQuantities[sku];
        } else {
            newQuantities[sku] = parseInt(count);
        }

        return setQuantities(newQuantities);
    }
    const setField = (field, value) => {
        const newData = { ...data };
        newData[field] = value;
        return setData(newData);
    }

    const getWholesale = (price, discount = 50) => {
        return Math.round(price / 1.23 * (100 - discount) / 100);
    }

    window.APP_SETTINGS.productGroups.map(g => {
        return g.products.map(p => {
            if (p.variants) {
                Object.entries(p.variants).map(([variantName, v], index) => {
                    v.wholesalePrice = getWholesale(v.price)
                    variants[v.SKU] = { ...v };
                    variants[v.SKU].variant = variantName;
                    variants[v.SKU].name = g.name;
                });
            } else {
                p.wholesalePrice = getWholesale(p.price)
                variants[p.SKU] = { ...p };
                variants[p.SKU].name = g.name;
                variants[p.SKU].wholesalePrice = getWholesale(p.price)
            }

        });
    });

    const getCart = () => {
        const MOQ = window.APP_SETTINGS?.clientSettings?.MOQ || 0;
        const DISCOUNT_LEVELS = window.APP_SETTINGS?.clientSettings?.DISCOUNT_LEVELS || [];
        let cart = {
            sum: 0,
            sumWholesale: 0,
            pcs: 0,
            discount: 0,
            items: [],
        };

        cart.items = Object.entries(quantities).map(([sku, count], index) => {
            if (variants[sku]) {
                let v = variants[sku];
                cart.sum += count * v.price;
                cart.pcs += count;
                return {
                    lp: index + 1,
                    name: v.name,
                    variant: v.variant,
                    SKU: v.SKU,
                    count: count,
                    price: v.price,
                    value: count * v.price,
                }
            }
        }).filter(n => n); // wyrzuca produkty nieistniejące

        DISCOUNT_LEVELS.map(({ min, discount }, i) => {
            if (cart.sum >= min) {
                cart.discount = discount;
                if (DISCOUNT_LEVELS[i + 1]) {
                    cart.nextDiscount = {
                        discount: DISCOUNT_LEVELS[i + 1].discount,
                        level: DISCOUNT_LEVELS[i + 1].min,
                        missing: DISCOUNT_LEVELS[i + 1].min - cart.sum
                    }
                } else {
                    cart.nextDiscount = undefined;
                }
            }
        })
        cart.sumDiscounted = cart.sum * (100 - cart.discount) / 100;

        cart.items.map((item) => {
            item.wholesalePrice = getWholesale(item.price, cart.discount);
            item.wholesaleValue = item.count * item.wholesalePrice;
            cart.sumWholesale += item.wholesaleValue;
        });

        if (MOQ > cart.sumWholesale) {
            cart.moq = {
                value: MOQ,
                missing: MOQ - cart.sumWholesale
            }
        }


        return cart;
    }

    return (
        <OrderContext.Provider value={{ quantities, setQuantity, data, setField, getCart, clearAll, isLocked, setLock }}>
            {children}
        </OrderContext.Provider>
    )
}

const useOrderContext = () => {
    const context = React.useContext(OrderContext)

    if (context === undefined) {
        throw new Error('useOrderContext must be used within OrderProvider')
    }
    return context
}

export { OrderProvider as default, useOrderContext }