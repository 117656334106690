import React from 'react';
import ProductWithVariants from './ProductWithVariants';

import './ProductGroup.scss';
import ProductVariant from './ProductVariant';

const ProductGroup = ({ group = {} }) => {
    const hasVariants = group?.variants?.length > 0;
    return (
        <section className="product-group">
            <h2>{group.name}</h2>
            {!hasVariants && <div className="product-group__products">
                {group.products.map((p, i) => <ProductVariant key={i} variant={p} />)}
            </div>}
            {hasVariants && <>
                <div className="product-group__variant">
                    <div className="product-group__products">
                        <div className="product-variant__wrapper"></div>
                        {group.variants.map((v, idx) => {
                            return <div className="product-variant__wrapper">{v}</div>
                        })}
                    </div>
                </div>
                {group.products.map((p, i) => <ProductWithVariants key={i} product={p} variants={group.variants} />)}
            </>}
        </section>
    );
}


export default ProductGroup